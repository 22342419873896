// extracted by mini-css-extract-plugin
export var accordionWrapper = "dashboard-module--accordionWrapper--98c40";
export var analysis = "dashboard-module--analysis--35795";
export var billetShadow = "dashboard-module--billet-shadow--f1aaf";
export var colorScheme__background__grayPale = "dashboard-module--colorScheme__background__gray-pale--82b3a";
export var dashVideo = "dashboard-module--dashVideo--e5993";
export var dashboardBlock = "dashboard-module--dashboardBlock--09be1";
export var dashed = "dashboard-module--dashed--5654e";
export var dashed__title = "dashboard-module--dashed__title--85616";
export var flexBlock = "dashboard-module--flexBlock--57c0c";
export var headline = "dashboard-module--headline--3d933";
export var headline_grid = "dashboard-module--headline_grid--d79a0";
export var how = "dashboard-module--how--4c25a";
export var icomoon = "dashboard-module--icomoon--89eb7";
export var iconFont = "dashboard-module--iconFont--31130";
export var lowercase = "dashboard-module--lowercase--74bff";
export var services = "dashboard-module--services--11e3a";
export var strongText = "dashboard-module--strongText--51a88";
export var strongTextBlock = "dashboard-module--strongTextBlock--9b979";
export var transition = "dashboard-module--transition--586ee";
export var transitionBackground = "dashboard-module--transition-background--dedd5";
export var transitionReverse = "dashboard-module--transition-reverse--aa112";
export var uppercase = "dashboard-module--uppercase--97573";
export var videoBlock = "dashboard-module--videoBlock--d366f";
export var videoWrapper = "dashboard-module--videoWrapper--b224e";