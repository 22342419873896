import React, { useEffect, useState } from "react"
import cx from "classnames"
import { graphql, useStaticQuery, Link } from "gatsby"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import { VIDEO, IMG } from "../const"
import {
  DASHBOARD_PROPERTIES,
  OPTIONS_LIST,
  SERVICES,
  STRUCTURED,
  TITLES,
} from "../data/dashboardPage"
import "../assets/styles/commons/_common-kit.scss"
import "../assets/styles/portfolio-chapter.scss"
import PortfolioImagesContainer from "../components/PortfolioImagesContainer"
import * as styles from "../assets/styles/dashboard.module.scss"
import Headline from "../components/headline"
import OptionsList from "../components/options-list"
import ServicesList from "../components/services-list"
import Accordion from "../components/accordion"
import TitleBlock from "../components/title-block"

const ColoredSection = loadable(() => import("../components/colored-section"))
const ContactForm = loadable(() => import("../components/contact-form"))

const PROJECTS_ORDER = [
  "Kamsa",
  "Recruitment Agency Dashboard",
  "Call Listening",
]

const DashboardPage = () => {
  const data = useStaticQuery(graphql`
    query {
      portfolioJson {
        name
        projects {
          title
          description
          metaTitle
          tagList
          country
          flagIconUrl
          imageLink
          mainBgImage
          mainPortfolioImage
          pageList
          externalLink
          slug
        }
      }
    }
  `)

  const [projectsList, setProjectsList] = useState([])

  useEffect(() => {
    let list = []

    PROJECTS_ORDER.forEach(item => {
      const existingProject = data.portfolioJson.projects.find(
        project => project.title === item
      )

      if (existingProject) {
        existingProject.pageList?.length > 0 &&
          existingProject.pageList.map(
            page => page === "dashboard" && list.push(existingProject)
          )
      }
    })

    setProjectsList(list)
  }, [data.portfolioJson.projects])

  return (
    <Layout
      pageTitle="Dashboard development"
      metaDescription="Efficient data management at your fingertips with LaSoft's intuitive dashboard solutions. Stay in control, make informed decisions."
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-dashboard.jpg`}
    >
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={cx(styles.dashboardBlock, "common-kit")}>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className={"strongText"}>
                      with 10+ years of experience in
                    </p>

                    <h1 className={"withIndent"}>
                      <strong>Dashboard</strong> development
                    </h1>

                    <div className={"rightColumn"}>
                      <p>
                        We have been actively improving the business
                        intelligence capabilities of our clients since 2014. Our
                        Data Visualization and Data Analytics experts make
                        custom dashboards, which help visualize data to make
                        data-driven decisions.
                      </p>
                      <div className="onDesktop">
                        <Link to="#contact-form" className="goldFatLink">
                          Let’s talk
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={styles.videoBlock}>
                    <div className={styles.videoWrapper}>
                      <div>
                        <video
                          poster={`${VIDEO}/dashboard/1080.jpg`}
                          autoPlay
                          muted
                          loop
                          playsInline
                          preload="auto"
                          className={cx(styles.dashVideo)}
                        >
                          <source
                            src={`${VIDEO}/dashboard/1080.mp4`}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </div>

                  <div className={styles.flexBlock}>
                    <div className={styles.strongTextBlock}>
                      <h3 className={styles.strongText}>our clients are:</h3>
                    </div>
                    <OptionsList list={OPTIONS_LIST} />
                  </div>
                  <div className="onMobile">
                    <Link to="#contact-form" className="goldFatLink">
                      Let’s talk
                    </Link>
                  </div>
                </div>
              </Headline>

              <section className={cx(styles.analysis, "section")}>
                <TitleBlock options={TITLES.analysis} />
                <ul>
                  {DASHBOARD_PROPERTIES.map(item => (
                    <li key={item.id}>
                      <div className={cx(styles.iconFont, item.icon)} />
                      <div>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>

              <section className={cx(styles.services, "section")}>
                <TitleBlock options={TITLES.services} />
                <ServicesList services={SERVICES.items} />
              </section>

              {/* <Integrations
                title={TITLES.stack}
                options={INTEGRATIONS}
                initialOptions={INTEGRATIONS[0]}
                screenWidth={width}
              /> */}

              {/* ------ Case Studies ------ */}
              <div className="portfolioChapter__list">
                {projectsList.length > 0 &&
                  projectsList.map(item => (
                    <section
                      className="portfolioChapter__singleProjectSummary"
                      key={item.slug || item.title}
                    >
                      <div className="portfolioChapter__singleProjectSummary__text">
                        <div className="portfolioChapter__singleProjectSummary__text__description">
                          <h2>
                            {item.externalLink ? (
                              <a
                                href={item.externalLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.title}
                              </a>
                            ) : item.slug ? (
                              <Link to={`/${item.slug}/`}>{item.title}</Link>
                            ) : (
                              item.title
                            )}
                          </h2>
                          <ul className="tags">
                            <li className="flag">
                              <img
                                src={`${IMG.INDEX}/${item.flagIconUrl}`}
                                width="22"
                                height="16"
                                alt={`${item.country} flag`}
                                title={`${item.country} flag`}
                                loading="lazy"
                              />
                            </li>
                            {item.tagList.map(tag => (
                              <li key={`tag_${tag.replace(/\s/g, "")}`}>
                                {tag}
                              </li>
                            ))}
                          </ul>
                          <div
                            className="portfolioChapter__singleProjectSummary__visual mobileOnly"
                            data-title={item.title}
                          >
                            <PortfolioImagesContainer item={item} />
                          </div>
                          <div className="info">
                            <p>{item.description}</p>
                          </div>
                          {item.externalLink && (
                            <a
                              href={item.externalLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="portfolioChapter__seeMore"
                            >
                              Discover project
                            </a>
                          )}
                          {item.slug && (
                            <Link
                              to={`/${item.slug}/`}
                              className="portfolioChapter__seeMore"
                            >
                              Discover project
                            </Link>
                          )}
                        </div>
                      </div>
                      <div
                        className="portfolioChapter__singleProjectSummary__visual"
                        data-title={item.title}
                      >
                        <PortfolioImagesContainer item={item} />
                      </div>
                    </section>
                  ))}
              </div>

              <section className={cx(styles.how, "section")}>
                <TitleBlock options={TITLES.structured} />
                <div className={styles.accordionWrapper}>
                  <Accordion options={STRUCTURED} />
                </div>
              </section>

              <ColoredSection options={TITLES.colored} />

              <section className={cx(styles.dashed, "section")}>
                <div>
                  <h2 className={styles.dashed__title}>
                    Elevate Your Data Strategy with LaSoft.{" "}
                  </h2>
                  <p>
                    Our team of data scientists, designers, and developers are
                    experts in their field, bringing a wealth of experience and
                    creativity to each data visualization project. We pride
                    ourselves on delivering high-quality, accurate dashboards
                    that you can rely on for precise insights. <br />
                    Unlock the potential of your data with our dashboard
                    development services. Let us help you turn data into
                    solutions. Contact us today to get started!
                  </p>
                </div>
              </section>
            </article>
            <section className="section">
              <div className="footerForm common-kit" id="contact-form">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default DashboardPage
