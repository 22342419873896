import React from "react"

import { IMG, VIDEO } from "../const"

export const OPTIONS = [
  {
    id: 1,
    title: "Learn",
    text:
      "We carefully learn your need and data in order to understand your problem.",
    image: "/graduate-cap.svg",
  },
  {
    id: 2,
    title: "Draw",
    text:
      "Data visualization expert offers you several designs on how your data may look like on the dashboard",
    image: "/paintbrush.svg",
  },
  {
    id: 3,
    title: "Implement",
    text:
      "Our development team creates the dashboard and integrates it with your system.",
    image: "/code-board.svg",
  },
  {
    id: 4,
    title: "Test",
    text:
      "Using dashboard with your team you make sure the data make sense and is enough for effective decision making.",
    image: "/test-tube.svg",
  },
]

export const DASHBOARD_PROPERTIES = [
  {
    id: 1,
    title: "Innovative",
    text:
      "We follow trends in data visualization techniques, beautiful dashboard design and user experience.",
    icon: "icon-bulb",
  },
  {
    id: 2,
    title: "Flexible",
    text:
      "Visualize data and make your charts and widgets user friendly, placing them wherever you want.",
    icon: "icon-shuffle",
  },
  {
    id: 3,
    title: "Interactive",
    text:
      "Make ongoing decisions easy and fast due to detailed reports and notifications about important updates.",
    icon: "icon-touch",
  },
  {
    id: 4,
    title: "Customised",
    text:
      "Interactive dashboard can be customized for all employee levels and used in all business processes, presenting real-time data.",
    icon: "icon-settings",
  },
  {
    id: 5,
    title: "Easy to integrate",
    text:
      "Our engineers will create a plan for easy data integration and retrieval from various sources.",
    icon: "icon-puzzle",
  },
  {
    id: 6,
    title: "Secure",
    text:
      "Secure transfer of your essential metrics prevents it from falling into third parties’ hands.",
    icon: "icon-protection",
  },
]

export const TITLES = {
  analysis: {
    title: (
      <>
        <strong>exceptional analytics:</strong> our high-quality dashboard
        solutions
      </>
    ),
    strongText: "interactive dashboards specially tailored to  your needs",
    uppercase: (
      <>
        LaSoft is your dashboard
        <br /> development company
      </>
    ),
    withIndent: true,
  },
  services: {
    title: (
      <>
        <strong>advanced </strong>
        dashboard solutions
      </>
    ),
    strongText: "data visualizations for different needs",
    uppercase: "a wide range of services",
    withIndent: true,
  },
  stack: {
    title: (
      <>
        data visualization <strong>stack</strong>
      </>
    ),
    strongText: "dashboard development tech stack",
    uppercase: (
      <>
        technologies <br />
        and integrations
      </>
    ),
    withIndent: true,
  },
  structured: {
    title: (
      <>
        how does <strong> data visualization</strong> work?
      </>
    ),
    strongText:
      "data visualization dashboard is the best way of finding important management insights",
    uppercase: "Structured and Tested",
    withIndent: true,
  },
  colored: {
    title: (
      <>
        interested in
        <br /> advanced data
        <br /> visualization?
      </>
    ),
    strongText:
      "request a consultation today, so that we can help build your data dashboard",
    uppercase: "Project estimate",
    link: "#contact-form",
    imageDesktop: `${IMG.DASHBOARD}/scheme-white.svg`,
    imageDesktopWidth: 1085,
    imageDesktopHeight: 92,
    withIndent: false,
    industry: "dashboard",
  },
}

export const VIDEO_LIST = [
  {
    id: 1,
    source: `${VIDEO}/dashboard/0480`,
    className: "range0_479",
  },
  {
    id: 2,
    source: `${VIDEO}/dashboard/0640`,
    className: "range480_639",
  },
  {
    id: 3,
    source: `${VIDEO}/dashboard/0720`,
    className: "range640_719",
  },
  {
    id: 4,
    source: `${VIDEO}/dashboard/1080`,
    className: "range720_1079",
  },
  {
    id: 5,
    source: `${VIDEO}/dashboard/1280`,
    className: "range1080_1279",
  },
  {
    id: 6,
    source: `${VIDEO}/dashboard/1920`,
    className: "range1280",
  },
]

export const OPTIONS_LIST = [
  {
    id: 1,
    title: "Startups",
    text:
      "that require analytics dashboards, but lack a tech team in the early stages.",
  },
  {
    id: 2,
    title: "Founders",
    text: "who want to make well informed decisions faster.",
  },
  {
    id: 3,
    title: "Heads of IT dep",
    text: "who need to build custom data analytics software.",
  },
  {
    id: 4,
    title: "Consultants",
    text: "who want to show accurate data properly and beautifully visualized.",
  },
]

export const SERVICES = {
  items: [
    {
      id: 1,
      title: "Business Development Dashboards",
      text: (
        <ul>
          <li>Monitor the progress of a company's strategic business goals;</li>
          <li>
            Analyze and compare financial indicators to find the best strategy
            for revenue optimization;
          </li>
          <li>
            Analyze and monitor the efficiency of departments and employees;
          </li>
          <li>Product and Customer business analytics;</li>
        </ul>
      ),
    },
    {
      id: 2,
      title: "HR and Recruitment Dashboards",
      text: (
        <ul>
          <li>
            Monitor employees’ activities to determine the performance gaps in
            their work and devise ways to improve their productivity;
          </li>
          <li>
            Visualize applicant data, current recruitment needs, and best
            performers can help you make informed decisions within the
            recruitment process;
          </li>
          <li>
            Identify the drivers of employee satisfaction as well as turnover,
            and use this knowledge to retain happy employees while firing less
            productive ones;
          </li>
        </ul>
      ),
    },
    {
      id: 3,
      title: "Marketing Dashboards",
      text: (
        <ul>
          <li>
            Prospects’ responses to marketing activities can be predicted. These
            analytical dashboards can help with planning and optimizing the
            budgeting of marketing campaigns;
          </li>
          <li>
            Using customer segmentation through data, a company can create
            personalized marketing campaigns and use cross-selling and upselling
            tactics;
          </li>
          <li>
            Increasing product and service quality via customer digital
            analytics;
          </li>
          <li>CRM analytics to optimize the sales funnel;</li>
          <li>Survey data analysis;</li>
        </ul>
      ),
    },
    {
      id: 4,
      title: "Fintech Dashboards",
      text: (
        <ul>
          <li>
            Analyze a company's revenue, expenses, and profitability to develop
            better financial forecasts and budgets;
          </li>
          <li>Identify and avoid unnecessary financial risks;</li>
          <li>Predictive analytics in finance;</li>
        </ul>
      ),
    },
    {
      id: 5,
      title: "Manufacturing Dashboards",
      text: (
        <ul>
          <li>
            Analyze and interpret data to improve manufacturing equipment
            performance, efficiency, and quality;
          </li>
          <li>
            Detect manufacturing defects to speed up product development and
            increase quality;
          </li>
        </ul>
      ),
    },
  ],
}

export const INTEGRATIONS = [
  {
    name: "Data visualization",
    items: [
      { name: "Power BI", icon: "microsoft-power-bi.svg" },
      { name: "Excel", icon: "excel.svg" },
      { name: "Google Analytics", icon: "google-analytics.svg" },
      { name: "D3 JS", icon: "d3-js.svg" },
      { name: "Pandas", icon: "pandas.svg" },
      { name: "Looker Studio", icon: "looker-studio.svg" },
      { name: "Airflow Apache", icon: "airflow-apache.svg" },
    ],
  },
  {
    name: "Front-end programming languages",
    items: [
      { name: "JS", icon: "javascript.svg" },
      { name: "Angular", icon: "angular.svg" },
      { name: "React", icon: "react.svg" },
    ],
  },
  {
    name: "DWS services & Database",
    items: [
      { name: "PostgreSQL", icon: "postgresql.svg" },
      { name: "MySQL", icon: "mysql.svg" },
      { name: "MariaDB", icon: "mariadb-seal.svg" },
      { name: "MongoDB", icon: "mongodb.svg" },
    ],
  },
  {
    name: "Data management",
    items: [
      { name: "AWS", icon: "aws.svg" },
      { name: "Tableau", icon: "tableau.svg" },
    ],
  },
]

export const STRUCTURED = [
  {
    id: 1,
    title: "Needs discovery",
    text:
      "We diligently learn your requirements to gain a comprehensive understanding of your challenges. Subsequently, we provide you with a tailored and optimal solution.",
  },
  {
    id: 2,
    title: "Data Collection",
    text:
      "The first step of data visualization is collecting data sets from various company departments, products, and service sources. We must ensure we are gathering and consolidating the correct data—otherwise, our analysis will be inaccurate.",
  },
  {
    id: 3,
    title: "Data Modeling",
    text:
      "We apply innovative data management and visualization techniques to each client to meet their needs. Our customized data visualization services allow you to choose the visual chart or graph that covers your needs to easily monitor your data and highlight insights. We analyze various charts and graphs before selecting the one best suited to visual representation of different aspects of your data sets.",
  },
  {
    id: 4,
    title: "Data Cleaning",
    text:
      "Data cleaning is crucial to ensure the quality of your data, and we guarantee that our client's data has been thoroughly checked before producing any graphics based on it.",
  },
  {
    id: 5,
    title: "Data Visualization",
    text:
      "Our process transforms data into beautiful dashboards, enriched with insightful graphs.",
  },
  {
    id: 6,
    title: "Dashboard Testing",
    text:
      "Additionally, we rigorously test these dashboards with your team to ensure accuracy, usability, and seamless integration with your existing systems, guaranteeing a reliable and effective data visualization experience.",
  },
]
