import React from "react"

import "./accordions.scss"

const Accordion = ({ options }) => (
  <ol className="common__accordionOrdered__markerPlusMinus">
    {options.map(option => (
      <li key={option.id} data-attr-id={option.id}>
        <details>
          <summary>
            <h3>{option.title}</h3>
          </summary>
          <div className="content">
            {typeof option.text === "string" ? (
              <p>{option.text}</p>
            ) : (
              option.text
            )}
          </div>
        </details>
      </li>
    ))}
  </ol>
)

export default Accordion
